<template>
    <div>
        <van-search v-model="keyword" @search="handleSearch" @clear="handleClear" placeholder="请输入关键词搜索"/>
        <middle-loading :loading="categoryLoading" v-if="categoryLoading" />

        <van-tabs v-else @change="handleTabChange"  sticky color="#1989fa">
            <van-tab :key="category.id" :name="category.id" v-for="category in categories" :title="category.name">
                <van-list
                    v-model="listLoading"
                    :finished="listFinished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    :error.sync="error"
                    error-text="加载,点击重新加载"
                    :ref="'mList' + category.id"
                >
                    <audio-item v-for="audio in audios.data" :key="audio.id" :audio="audio"></audio-item>
                </van-list>
            </van-tab>
        </van-tabs>
        <tabbar />
    </div>
</template>

<script>
import { getCategories } from '@/api/category'
import { getAudios, getChurchByDomain } from '@/api/media'
import AudioItem from './AudioItem'
import Tabbar from '@/components/Tabbar'
import MiddleLoading from '@/components/MiddleLoading'

export default {
    name: 'AudioIndex',

    components: { AudioItem, Tabbar, MiddleLoading },
    data() {
        return {
            keyword: null,
            categoryLoading: true,
            categories: [
                {
                    name: '全部',
                    id: 0
                }
            ],
            listLoading: false,
            listFinished: false,
            error: false,

            audios: {
                meta: {
                    current_page: 0,
                    last_page: 0,
                },
                data: []
            }
        }
    },

    methods: {
        handleSearch () {
            this.handleTabChange(this.category_id)
        },
        handleClear () {
            this.keyword = null
            this.handleTabChange(this.category_id)
        },
        handleTabChange(index) {
            this.category_id = index || null
            this.audios = {
                meta: {
                    current_page: 0,
                    last_page: 0,
                },
                data: []
            }
            this.listFinished = false
        },
        async onLoad() {
            const churchRes = await getChurchByDomain()

            getAudios({
                church_id: churchRes.data.id,
                page: this.audios.meta.current_page + 1,
                category_id: this.category_id,
                title: this.keyword,
            }).then(res => {
                res.data.unshift(...this.audios.data)
                this.audios = res
                this.listLoading = false
                // 数据全部加载完成
                if (res.meta.current_page === res.meta.last_page) {
                    this.listFinished = true
                }
            }).catch(() => {
                this.error = true
            })
        }
    },
    created() {
        getCategories({type: 'audio'}).then(res => {
            this.categories.push(...res.data)
            this.categoryLoading = false
        })
    }
};
</script>
