<template>
    <van-cell :title="audio.title" :to="'/audio/' + audio.id" class="audio-item">
        <template #default>
            <my-image :src="audio.thumb_vlist"></my-image>
        </template>
        <template #label>
            <van-row type="flex">
                <van-col span="24" class="audio-meta">
                    <template v-if="audio.author !== ''">
                        <van-icon name="manager-o"/>
                        <span>{{ audio.author }}</span>
                    </template>
                    <van-icon name="notes-o"/>
                    <span>{{ audio.created_at }}</span>
                    <van-icon name="play-circle-o"/>
                    <span>{{ audio.views }}</span>
                </van-col>
            </van-row>

        </template>
    </van-cell>
</template>

<script>
import MyImage from '@/components/MyImage'
export default {
    name: 'AudioItem',
    props: {
        audio: {
            required: true,
            type: Object
        }
    },
    components: { MyImage }
};
</script>

<style lang="less">
    .audio-item {
        .van-cell__title {
            flex-grow: 3;
        }
        .van-cell__value {
            flex-grow: 1;
            padding-left: 5px;
        }
        .audio-meta {
            display: flex;
            align-items: center;
            i {
                margin-right: 5px;
            }
            span {
                margin-right: 10px;
            }
        }
    }
</style>
